import React, { Component } from 'react';
import Layout from '../components/layout.js';
import ChapterHero from '../components/chapter_hero.js';
import DecoBorder from '../components/deco_border.js';
import Anime from 'animejs/lib/anime.es.js';
import CallToAction from '../components/call_to_action.js';
import { Helmet } from "react-helmet";
import '../style/sunset_opportunities.scss';
import '../style/fonts.scss';
import '../style/chapters.scss';

class SunsetOpportunities extends Component {

  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
  }

  state = {
    isVisible: false
  }

  handleFadeIn() {

    var timeline = Anime.timeline({
      easing: 'easeOutQuart',
      duration: 0
    });

    timeline.add({
          targets: '.digit-01, .digit-02',
          translateY: function(el, i) {
            return 20 + (-40 * i);
          },
          opacity: 1,
          duration: 800,
          easing: 'easeInOutSine'
      }).add({
        targets: '.dynamic-number-label-01',
        opacity: 1,
        duration: 800,
        easing: 'linear'
      }).add({
        targets: '.digit-03, .digit-04',
        translateY: function(el, i) {
          return 20 + (-40 * i);
        },
        opacity: 1,
        duration: 800,
        easing: 'easeInOutSine'
      }).add({
        targets: '.dynamic-number-label-02',
        opacity: 1,
        duration: 800,
        easing: 'linear'
      });

  }

  getYear = () => {
    const year = new Date().getFullYear();
    return year;
  }

  fadeInOnPageLoad() {
  //   // If the initial window is high enough to show elements
    let element = document.querySelector('.dynamic-number-container');

    if (element !== undefined) {
      if ((window.pageYOffset >= (window.pageYOffset + element.getBoundingClientRect().top - (.95 * window.innerHeight))) && (this.state.isVisible === false)) {
        // fade them in
        this.setState({isVisible: true})
        this.handleFadeIn();
      }
    }
  }

  handleScroll() {
    let element = document.querySelector('.dynamic-number-container');

    if (element !== undefined) {
      if ((window.pageYOffset >= (window.pageYOffset + element.getBoundingClientRect().top - (.7 * window.innerHeight))) && (this.state.isVisible === false)) {
        this.setState({isVisible: true});
        this.handleFadeIn();
      }
    }

  }

  componentDidMount() {
    this.fadeInOnPageLoad();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    this.setState({isVisible: false});
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Sunset Opportunities</title>
          <link rel="canonical" href="https://www.tobyrichey.com/sunset_opportunities" />
        </Helmet>
        <ChapterHero
          headline='Exit With Confidence.'
          subheader='Strategies for retiring advisors.'
          classList='chapter-hero-container'
          img={this.props.data.picnic.childImageSharp.fluid}/>
        <DecoBorder />
        <div id='sunset-opportunities-container'>
          <div id='sunset-opportunities' className='chapter-content'>
            <div className='body-container'>
              <div className='text-block'>
                <div id='text'>
                  <div id='numbers-container'>
                    <div className='dynamic-number-container'>
                      <div className='dynamic-number'>
                        <div className='digit-01'>
                          5
                        </div>
                        <div className='digit-02'>
                          1
                        </div>
                        <h6 className='dynamic-number-label-01'>
                          Average advisor age<br />in {this.getYear()}
                        </h6>
                      </div>
                    </div>
                    <div className='dynamic-number-container'>
                      <div className='dynamic-number'>
                        <div className='digit-03'>
                          3
                        </div>
                        <div className='digit-04'>
                          8
                        </div>
                        <h6 className='dynamic-number-label-02'>
                          Percent of advisors retiring within 10 years
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className='hor-rule' />
                  <h3>More advisors than ever are expected to retire in the next decade.</h3>
                  <p>
                  In response, financial services firms have become
                  extremely creative when customizing succession plans for advisors
                  seeking a transition into retirement. Advisors want confidence that
                  valued clients are with the best firm to continue a legacy of
                  responsible wealth management and excellent service. With more
                  flexibility in terms of shortened contracts and lucrative offers,
                  there has never been a better time to explore exit strategies.
                  </p>
                  <div className='hor-rule' />
                  <CallToAction
                    body='Let Toby Richey & Associates navigate the landscape of opportunities that are available to you.'
                    email='info@tobyrichey.com'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="svg-outer svg-outer-chapter-page">
            <svg className='svg-inner gray' width='100%' height='100%' viewBox="0 0 100 100" preserveAspectRatio="none">
             <polygon points="0,0 0,100 100,100 100,0 50,98" />
            </svg>
          </div>
        </div>
      </Layout>
    )
  }

}

export const query = graphql`
  {
    picnic: file(relativePath: { eq:
    "picnic.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }`
;

export default SunsetOpportunities;
